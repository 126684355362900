.container {
	height: 100%;
	padding-top: 55px;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #E7E7E7;
}
.form {
	border-radius: 20px;
	height: 416px;
	width: 364px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 30px;
	background-color: #fff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.title {
	font-size: 24px;
	padding-bottom: 10px;
	margin: 20px 0;
}
.input {
	padding: 4px;
	line-height: 38px;
	border-radius: 10px;
	border-color: #3B3B3B;
}
.button {
	margin-bottom: 12px;
	
	button {
		width: 100%;
		height: 46px;
		border-color: none;
		border-radius: 10px;
		background: linear-gradient(to right,#464E9E, #58B7CC);
		font-size: 16px;
		color: #fff;
	}
}
.label {
	font-weight: bold;
	margin: 0 0 4px 0;
	padding-left: 10px;
	font-size: 16px;
	line-height: 20px;
	text-align: start;
}
.info {
	font-size: 12px;
}
.error {
	font-size: 10px;
	color: #DB5A5A;
	margin-bottom: 8px;
}

.image {
	transform: translate3d(0, 0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}