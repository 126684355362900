.creationSuccess {
	width: 486px !important;
	.ant-modal-content {
		border-radius: 20px;
		height: 486px;
		width: 486px;
		padding: 50px 30px 30px;
		display: flex;
		flex-direction: column;
 	}
	.ant-modal-title  {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 6px;
		text-align: center;
	}
	.ant-modal-body {
		font-size: 16px;
		line-height: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		img {
			width: 200px;
		}
		p {
			text-align: center;
		}
		.chatWithBot {
			position: relative;
			width: 310px;
			height: 46px;
			color: #FFF;
			background: linear-gradient(to right,#464E9E, #58B7CC);
			border-radius: 10px;
			font-size: 16px;
			font-weight: 700;
			margin-top: 20px;
		}
	}
}