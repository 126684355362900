.container {
	position: fixed;
	width: 78%;
	background-color: grey;
	height: 80px;
	min-height: 80px;
	display: flex;
	justify-content: flex-end;
	padding-top: 24px;
	padding-right: 50px;
	border-top: 8px solid #E7E7E7;
	border-radius: 0 20px 0 0 ;
	z-index: 1;
}

.userImage{
	position: relative;
	width: 32px;
	height: 32px;
	margin-left: 24px;
	&:hover {
		cursor: pointer;
	}
}

