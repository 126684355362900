.listItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	background-color: #fff;
	margin: 16px 50px;
	padding: 16px 24px;
	border-radius: 20px;
	box-shadow: 0px 4px 4px rgba(0,0,0,25%);
	&:hover {
		cursor: pointer;
		box-shadow: 0px 4px 4px rgba(0,0,0,50%);
	}
	div {
		display: flex;
		align-items: center;
		img {
			margin-right: 24px;
		}
	}
	button {
		font-size: 12px;
	}
}

.createBot {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	background-color: #fff;
	margin: 16px 50px;
	padding: 16px 24px;
	border-radius: 20px;
	box-shadow: 0px 4px 4px rgba(0,0,0,25%);
	color: #fff;
	background: linear-gradient(to right,#464E9E, #58B7CC);
		.button {
			padding: 0;
			margin-right: 24px;
			&:hover {
				background-color: #E7E7E7;
			}
			span {
				font-size: 26px;
				line-height: 26px;
			}
		}
		&:hover {
			cursor: pointer;
			box-shadow: 0px 4px 4px rgba(0,0,0,50%);
		}
		div {
			display: flex;
			align-items: center;
			img {
				margin-right: 24px;
			}
		}
}