.tokenModal {
	width: 486px !important;
	.ant-modal-content {
		border-radius: 20px;
		height: 552px;
		width: 486px;
		padding: 50px 31px;
		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
 	}
	.ant-modal-title  {
		font-size: 24px;
		margin-bottom: 6px;
	}
	.ant-modal-body {
		font-size: 16px;
		line-height: 20px;
		.note {
			display: flex;
			height: 40px;
			padding: 8px 16px;
			line-height: 12px;
			border: 1px solid #3486B4;
			border-radius: 10px;
			font-size: 12px;
			color: #3486B4;
			background: rgba(52, 134, 180, 0.2);
			img {
				width: 10px;
				margin-right: 10px;
			}
			p {
				margin: 0;
			}
		}
		.stepsTitle {
			margin-top: 30px;
			font-weight: 700;
		}
		.steps {
			padding: 0 20px;
			li {
				margin: 20px 0;
			}
		}
		.help {
			position: relative;
			left: calc(50% - 155px);
			width: 310px;
			height: 46px;
			color: #3B3B3B;
			border: 1px solid #3B3B3B;
			border-radius: 10px;
			font-size: 16px;
			font-weight: 700;
		}
	}
}