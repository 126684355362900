.container{
	margin-bottom: 24px;
	.file {
		margin-top: 8px;
		display: flex;
		align-items: center;
		font-size: 14px;
		&:hover{ 
			background-color: rgba(0, 0, 0, 0.04);
			.delete{
				color: rgba(0, 0, 0, 0.45);
			}
		}
		.fileIcon {
			height: fit-content;
			width: 1em;
		}
		.fileName {
			margin: 0;
			padding: 0 8px;
			width: 100%;
		}
		.delete {
			color: transparent;
			margin-right: 2px;
			width: 1.5em;
			&:hover{ 
				cursor: pointer;
				color:#000;
			}
		}
	}
}

