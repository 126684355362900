.main_container {
	height: fit-content;
	display: flex;
	background-color: #E7E7E7;
}
@media (min-width: 1165px) {
	.inner_container{
		position: relative;
		left: calc(20% + 10px);
		width: 78%;
		display: flex;
		flex-direction: column;
	}
}
@media (max-width: 1164.9px) {
	.inner_container{
		position: relative;
		left: 240px;
		width: 78%;
		display: flex;
		flex-direction: column;
	}
}

.form {
	background-color: #fff;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	padding: 30px;
	margin: 125px 50px 35px 50px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.input {
	padding: 4px;
	line-height: 38px;
	border-radius: 10px;
	border-color: #3B3B3B;
}
.label {
	font-weight: bold;
	margin: 6px 0 4px;
	font-size: 16px;
	line-height: 20px;
}
.deleteBot {
	font-size: 12px;
	display: flex;
	align-items: center;
	img {
		margin-right: 8px;
	}
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}
