.label {
	font-weight: bold;
	margin: 6px 0 4px;
	font-size: 16px;
	line-height: 20px;
}
.sliderMarks {
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
}
.slider {
	width: 90%;
	margin: 0 auto;
}
.sliderMarks + .ant-form-item {
	margin-bottom: 0;
}
.ant-form-item-explain-error{
	margin-bottom: 6px;
}
.slider .ant-slider-rail {
	height: 1px;
	background-color: #3B3B3B;
}

.slider .ant-slider-track{
	background-color: #3B3B3B;
	height: 1px;
}

.slider .ant-slider-handle::after{
	background-color: #3B3B3B;
	box-shadow: 0 0 0 2px #3B3B3B;
}
.ant-slider-handle:hover::after {
	box-shadow: 0 0 0 2px #3B3B3B;
}
.ant-slider .ant-slider-handle:focus::after {
	box-shadow: 0 0 0 4px #3B3B3B;
}
.ant-slider .ant-slider-handle:active::after {
	box-shadow: 0 0 0 4px #3B3B3B;
}
.ant-slider:hover .ant-slider-handle::after {
	box-shadow: 0 0 0 2px #3B3B3B;
}
.ant-slider:hover .ant-slider-track {
	background-color: #3B3B3B;
}

.ant-slider:hover .ant-slider-rail {
	background-color: #3B3B3B;
}
.slider .ant-slider-dot {
	background-color: #3B3B3B;
	border-radius: 0;
	border: 1px solid #3B3B3B;
	width: 1px;
	height: 10px;
	top: -4px;
}
.ant-slider:hover .ant-slider-dot-active {
	border-color: #3B3B3B;
} 
