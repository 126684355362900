.main_container {
	height: 100vh;
	display: flex;
	background-color: #E7E7E7;
}

@media (min-width: 1165px) {
	.inner_container{
		position: relative;
		left: calc(20% + 10px)
	}
}

@media (max-width: 1164.9px) {
	.inner_container{
		position: relative;
		left: 240px;
	}
}

.inner_container{
	width: 78%;
	display: flex;
	flex-direction: column;
}
.main {
	height: 100%;
	margin: 90px 10px 10px 0;
	border-radius: 0 0 20px 0;
	// background-color: #E7E7E7;
}
.title {
	padding-left: 50px;
}


.button {
	width: 32px;
	height: 32px;
	background-color: #fff;
}

.loader {
	margin-top: 10%;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 100px;
		-webkit-animation: spin 4s linear infinite;
		 -moz-animation: spin 4s linear infinite;
		 animation: spin 4s linear infinite;
	}
	@-moz-keyframes spin { 
		100% { -moz-transform: rotate(360deg); } 
	}
	@-webkit-keyframes spin { 
		100% { -webkit-transform: rotate(360deg); } 
	}
	@keyframes spin { 
		100% { 
			-webkit-transform: rotate(360deg); 
			transform:rotate(360deg); 
		} 
	}
}
