.container {
	position: fixed;
	background: linear-gradient(#1E162B, #8C277D);
	color: #fff;
	width: 20%;
	height: calc(100% - 20px);
	min-width: 230px;
	padding-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	border-radius: 20px 0 0 20px;
	margin: 8px 0 10px 10px;
}
.main {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 20px;
}
.logo {
	cursor: pointer;
	height: 32px;
	margin-bottom: 85px;
}
.chatbots {
	cursor: pointer;
	background-color: #090909;
	display: flex;
	height: 45px;
	padding: 12px 40px;
	img {
		margin-right: 12px;
	}
}
.help {
	padding-left: 40px;
	display: flex;
	img {
		margin-right: 12px;
	}
}
.footer {
	padding-top: 20px;
	text-align: center;
	height: 68px;
	font-size: 12px;
	background-color: #1E162B;
	border-radius: 0 0 0 20px;
}