.label {
	font-weight: bold;
	margin: 6px 0 4px;
	font-size: 16px;
	line-height: 20px;
}
.input {
	padding: 4px;
	line-height: 38px;
	border-radius: 10px;
	border-color: #3B3B3B;
}
.fileSize {
	font-size: 12px;
	padding: 0 12px;
}
// .uploadError {
// 	display: flex;
// 	align-items: center;
// 	width: 280px;
// 	height: 30px;
// 	padding: 8px 16px;
// 	line-height: 12px;
// 	border: 1px solid #DB5A5A;
// 	border-radius: 10px;
// 	font-size: 10px;
// 	color: #DB5A5A;
// 	background: #FFE2E2;
// 	img {
// 		width: 10px;
// 		margin-right: 10px;
// 	}
// 	p {
// 		margin: 0;
// 	}
// }
.formItem{
	margin: 0;
}