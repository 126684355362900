.container {
	display: flex;
	flex-direction: column;
}
.text {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.label {
	font-weight: bold;
	margin: 6px 0 4px;
	font-size: 16px;
	line-height: 20px;
	width: fit-content;
}
.api {
	font-size: 12px;
	color: rgba(59, 59, 59, 0.7);
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}
.input {
	padding: 4px;
	line-height: 38px;
	border-radius: 10px;
	border-color: #3B3B3B;
}


